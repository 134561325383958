import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { IngresoPorTarjetaResult } from 'src/app/_shared/bloque-wallet-nuevo/_models/ingreso-tarjeta-result';
import { BaseService } from 'src/app/_services/base.service';
import { WalletMangoPayTransferencia } from 'src/app/_shared/bloque-wallet-nuevo/_models/wallet-mangopay-transferencia';
import { IngresoPorTransferencia } from 'src/app/_shared/bloque-wallet-nuevo/_models/ingreso-transferencia';
import { IngresoPorTarjeta } from 'src/app/_shared/bloque-wallet-nuevo/_models/ingreso-tarjeta';
import { PayOut } from 'src/app/_shared/bloque-wallet-nuevo/_models/payout';
import { Historico } from 'src/app/_shared/bloque-wallet-nuevo/_models/historico';
import { CheckMovimientoLW } from 'src/app/_shared/bloque-wallet-nuevo/_models/check-movimientos-lw';
import { WalletInfo } from 'src/app/_shared/bloque-wallet-nuevo/_models/wallet-info';


@Injectable({
  providedIn: 'root'
})
export class MangoPayService extends BaseService
{

  private wallet: Subject<WalletInfo> = new Subject<WalletInfo>();
  wallet$ = this.wallet.asObservable();

  constructor(httpClient: HttpClient)
  {
    super(httpClient, "", "");
  }

  //#region WalletStatus
  viewWallet(): Observable<WalletInfo>
  {
    return this._http.get<WalletInfo>(`${this._apiCoreUrl}v1/wallet`);
  }

  walletChange(walletInfo: WalletInfo)
  {
    this.wallet.next(walletInfo);
  }
  //#endregion

  //#region Ingresos y Retiradas
  ingresoPorTransferencia(moneyIn: IngresoPorTransferencia): Observable<WalletMangoPayTransferencia>
  {
    return this._http.post<WalletMangoPayTransferencia>(`${this._apiCoreUrl}v1/Wallet/PayInTransfer`, moneyIn);
  }
  
  ingresoPorTarjeta(dto: IngresoPorTarjeta): Observable<IngresoPorTarjeta>
  {
    return this._http.post<IngresoPorTarjeta>(`${this._apiCoreUrl}v1/Wallet/PayInCard`, dto);
  }

  ingresoPorTarjetaResult(id: string): Observable<IngresoPorTarjetaResult>
  {
    return this._http.get<IngresoPorTarjetaResult>(`${this._apiCoreUrl}v1/Wallet/PayInCardResult?transactionId=${id}`);
  }

  retiradaWallet(payOut: PayOut): Observable<void>
  {
    return this._http.post<void>(`${this._apiCoreUrl}v1/Wallet/PayOut`, payOut);
  }
  //#endregion

  //#region Historico
  historicoWallet(page: number, limit: number): Observable<Historico[]>
  {
    return this._http.get<Historico[]>(`${this._apiCoreUrl}v1/Wallet/Transactions?page=${page}&limit=${limit}`);
  }

  checkMovimientosLW(): Observable<CheckMovimientoLW>
  {
    return this._http.get<CheckMovimientoLW>(`${this._apiFactoringUrl}v1/Wallet/CheckMovimientosLW`);
  }

  downloadHistoricoWallet(): Observable<Blob>
  {
    return this._http.get(`${this._apiCoreUrl}v1/Wallet/DownloadReports`, { responseType: "blob" });
  }

  downloadMovimientosLW(): Observable<Blob>
  {
    return this._http.get(`${this._apiCoreUrl}v1/Wallet/DownloadLemonWay`, { responseType: "blob" });
  }
  //#endregion
}
